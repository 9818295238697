import React from 'react';
import { Accordion , Card} from 'react-bootstrap';
import { PaymentMethodECheck } from '../components/PaymentMethodECheck';
import { PaymentMethodCCForm } from '../components/PaymentMethodCCForm';
import { PaymentMethodsAccepted } from '../components/PaymentMethodsAccepted';
import { inject, observer } from 'mobx-react';
import achIcon from '../assets/images/ach.svg';
import cardIcon from '../assets/images/card.svg';
import axios from 'axios';
import '../assets/css/bootstrap.min.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/css/custom.css';
import {Helmet} from "react-helmet";
import { sanitizeCSS } from '../utils/sanitizeCss';

@inject('global' , 'vTerminal')
@observer
class PaymentMethod extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initToken: null,
            errorMessage: null,
            config: null,
            configCustomCss: null,
            customCssStyle: null
        };

        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.init = this.init.bind(this);
        this.functionCallBackSuccess = this.functionCallBackSuccess.bind(this);
        this.functionCallBackError = this.functionCallBackError.bind(this);
        this.resizeContainer = this.resizeContainer.bind(this);
    }

    init(res, config){
  
        if(res.data && res.data.ach && res.data.card && res.data.identifier){
            this.props.vTerminal.setPaymentMethodStatus('eCheck', typeof config.ach.enabled === 'undefined' ? res.data.ach.enabled : config.ach.enabled && res.data.ach.enabled );
            this.props.vTerminal.setPaymentMethodStatus('checking', typeof config.ach.checking === 'undefined' ? res.data.ach.checking : config.ach.checking && res.data.ach.checking );
            this.props.vTerminal.setPaymentMethodStatus('savings', typeof config.ach.savings === 'undefined' ? res.data.ach.savings : config.ach.savings && res.data.ach.savings);
            
            if(res.data.card.enabled === false || config.card.enabled === false){
                this.props.vTerminal.setPaymentMethodStatus('visa', false);
                this.props.vTerminal.setPaymentMethodStatus('mastercard', false);
                this.props.vTerminal.setPaymentMethodStatus('discover', false);
                this.props.vTerminal.setPaymentMethodStatus('amex', false);
                this.props.vTerminal.setPaymentMethodStatus('diners', false);
                this.props.vTerminal.setPaymentMethodStatus('jcb', false);
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("1");
            }else{
                this.props.vTerminal.setPaymentMethodStatus('visa', config.card.visa && res.data.card.visa);
                this.props.vTerminal.setPaymentMethodStatus('mastercard', config.card.mastercard && res.data.card.mastercard);
                this.props.vTerminal.setPaymentMethodStatus('discover', config.card.discover && res.data.card.discover);
                this.props.vTerminal.setPaymentMethodStatus('amex', config.card.amex && res.data.card.amex);
                this.props.vTerminal.setPaymentMethodStatus('diners', config.card.diners && res.data.card.diners);
                this.props.vTerminal.setPaymentMethodStatus('jcb', config.card.jcb && res.data.card.jcb);

            }
            if (config !== null && config.hasOwnProperty('customerData') && config.customerData !== null && Object.keys(config.customerData).length > 0) {
                this.props.vTerminal.setCustomerData(config.customerData);
            }
            if (config !== null && config.hasOwnProperty('paymentDetails') && config.paymentDetails !== null && Object.keys(config.paymentDetails).length > 0) {
                this.props.vTerminal.setPaymentDetails(config.paymentDetails);
            }
            this.props.vTerminal.setToken(res.data.identifier);
            this.setTokenUI(res.data.identifier);
            this.props.vTerminal.setEntryPoint(res.data.entryPoint);
        }
        else{
            this.setState({errorMessage: "The data format from server is incorrect.", initToken: null});
        }
    }

    componentDidMount(){
        try{
            let config = JSON.parse(window.atob(this.props.match.params.config));
            let defaultPaymentMethodSelectedToParent = "ach";
            if(config.defaultOpen){
                this.props.vTerminal.setDefaultPaymentMethodActiveKey(config.defaultOpen.toLowerCase() === 'card' ? "0" : "1");
                this.props.vTerminal.setPaymentMethod(config.defaultOpen.toLowerCase());
                defaultPaymentMethodSelectedToParent = config.defaultOpen.toLowerCase() === 'card' ? "card" : "ach"
            }
            else{
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
            }

            window.parent.postMessage({
                event: "callback-payabli-function-pay-method-selected"+config.randomId,
                data: defaultPaymentMethodSelectedToParent
            }, '*');

            if(config.temporaryToken === false){
                this.props.vTerminal.setTemporaryToken(false);
            }

            if(config && config.token){
                this.setState({token: config.token, errorMessage : null});
                this.props.global.setLoading(true);
				var murl = 'Tools/init/method';
                if (config.entryPoint && config.entryPoint.length > 0) {
                    murl = murl + '/' + config.entryPoint;
                }
                axios.get(process.env.REACT_APP_URL_API+ murl,{
                headers: {
                    'requestToken': config.token,
                }
                })
                .then(res => {
                    this.setState({config: config});
                    this.init(res, config);
                    window.parent.postMessage({
                        event: "callback-payabli-function-mounted-component"+config.randomId,
                        data: true
                    }, '*');
                    if(config.customCssUrl){
                        this.setState({
                            configCustomCss: config.customCssUrl
                        });
                    }

                    if(config.customCssStyle && typeof config.customCssStyle === 'string'){
                        const css = sanitizeCSS(config.customCssStyle)
                        this.setState({
                            customCssStyle: css
                        });
                    }
                    
                    this.props.global.setLoading(false);
                })
                .catch(error => {
                    console.error(error)
                    this.setState({errorMessage: error.message});
                    this.props.global.setLoading(false);
                    throw error;
                });
            }
            else{
                this.setState({errorMessage: "'token' is required", initToken: null});
            }
        }catch(error){
            this.setState({errorMessage: error.message, initToken: null});
        }

        this.resizeContainer();
        window.addEventListener('resize', this.resizeContainer);
        
    }

    resizeContainer(){
        let config = JSON.parse(window.atob(this.props.match.params.config));
        window.parent.postMessage({
            event: "callback-payabli-function-resize"+config.randomId,
            data: document.body.scrollHeight
        }, '*');
    }

    setTokenUI(token){
        let config = JSON.parse(window.atob(this.props.match.params.config));
        window.parent.postMessage({
            event: "callback-payabli-function-set-token"+config.randomId,
            data: token
        }, '*');
    }

    closeModal(){
        window.parent.postMessage("close-payabli-modal", '*');
    }

    functionCallBackSuccess(data) {
        let config = this.state.config;
        window.parent.postMessage({
            event: "callback-payabli-function-done"+config.randomId,
            data: data
        }, '*');
    }

    functionCallBackError(data) {
        let config = this.state.config;
        window.parent.postMessage({
            event: "callback-payabli-function-error"+config.randomId,
            data: data
        }, '*');
    }

    setPaymentMethod(method, activeKey){
        this.props.vTerminal.setPaymentMethod(method);
        if(activeKey!==null){
            this.props.vTerminal.setDefaultPaymentMethodActiveKey(activeKey);
        }

        let config = this.state.config;

        window.parent.postMessage({
            event: "callback-payabli-function-pay-method-selected"+config.randomId,
            data: method
        }, '*');

        let thisObj = this;
        setTimeout(function(){
            thisObj.resizeContainer();
        }, 100)
    }

    sendForm(){
        this.validateFields();
        if (!this.props.vTerminal.hasPaymentPageErrors()) {
            this.props.global.setLoading(true);
            if (this.props.vTerminal.customerData !== null && Object.keys(this.props.vTerminal.customerData).length >= 1) {
                this.props.vTerminal.addPaymentMethod().then(res => {
                    this.props.vTerminal.clearACHForm();
                    this.props.vTerminal.clearCCForm();
                    this.functionCallBackSuccess(res.data);
                    this.props.global.setLoading(false);
                })
                    .catch(error => {
                        console.error(error)
                        this.props.vTerminal.clearACHForm();
                        this.props.vTerminal.clearCCForm();
                        this.functionCallBackError([error]);
                        this.props.global.setLoading(false);
                    });
            }
            else {
                this.functionCallBackError(["customerError"]);
                this.props.global.setLoading(false);
            }
        }
        else {
            this.functionCallBackError(this.props.vTerminal.getPageErrors());
            this.props.global.setLoading(false);
        }
    }

    validateFields(){
        
        var paymentPage = this.props.vTerminal.paymentPage;
        var validators = this.props.global.validators;
        this.props.vTerminal.clearPaymentPageError();

        
        // validating ach form
        if(this.props.vTerminal.getPaymentMethod === "ach"){

            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccount) ||  validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            }
        }else if(this.props.vTerminal.getPaymentMethod === "card"){

            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);
            let ccType = this.props.global.creditCardType(paymentPage.paymentMethods.cardNumber);

            let ccTypeAux = ccType;
            if(ccType === "american-express"){
                ccTypeAux  =  "amex";
            }

            if(ccTypeAux === "unknown" || validators.isEmpty(paymentPage.paymentMethods.cardNumber) || validators.isMaxLength(16, paymentPage.paymentMethods.cardNumber) || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || this.state.config.card[ccTypeAux] === false)
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardCvv) || ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            }


            let globalConfig = this.state.config;
            let zipCodeValidator = validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode);
            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country && Array.isArray(globalConfig.card.inputs.cardZipcode.country)){
                zipCodeValidator = !this.props.global.checkZipFormat(globalConfig.card.inputs.cardZipcode.country, paymentPage.paymentMethods.cardZipcode)
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || zipCodeValidator)
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',"Your card's zip code is incomplete or incorrect");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',null);
            }

        }

    }

    render() {
       
        return (  
            <div id="main-container" className="lightbox">
            <h6 className="mb-3 text-left" style={{width: "100%"}}>Payment Method</h6>
            {this.state.errorMessage === null ? 
                <>   
                <Accordion activeKey={this.props.vTerminal.defaultPaymentMethodActiveKey} className="full-w">
                { this.props.vTerminal.hasECheck && 
                <Card className="card card-in mb-3">
                <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="1" onClick={(e) => this.setPaymentMethod('ach', "1")}>
                    <div className="row">
                        <div className="col-2 text-center">
                            <img alt="" style={{width: '44px'}} className="grey-icon-v2" src={achIcon}/>
                        </div>
                        <div className="col-10">
                            Bank account / E-Check
                            <p className="small small-grey-m0">
                            Pay from your Bank Account
                            </p>
                        </div>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <PaymentMethodECheck config={this.state.config} history={this.props.history} />
                </Card.Body>
                </Accordion.Collapse>
                </Card>
                }

                { this.props.vTerminal.hasCards  && 
                <Card className="card card-in mb-3">
                <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('card', "0")}>
                    <div className="row">
                        <div className="col-2 text-center">
                        <img alt="" style={{width: '46px'}} className="grey-icon-v2" src={cardIcon}/>
                        </div>
                        <div className="col-10">
                        Credit or Debit Card
                        <p className="small small-grey-m0">
                        Pay later and earn rewards
                        </p>
                        <div className="card-brands accordion-right-corner">
                        <PaymentMethodsAccepted/>
                        </div>
                        </div>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <PaymentMethodCCForm history={this.props.history} config={this.state.config}/>
                </Card.Body>
                </Accordion.Collapse>
                </Card>
                }
                </Accordion>
         
                <div className="row" style={{width: "100%", marginLeft: "-15px"}}>
                    <div className="col-sm-6 mb-3">
                        <button onClick={(e)=> this.closeModal()} className="btn full-w btn-light" type="button">Cancel</button>
                    </div>
                    <div className="col-sm-6 mb-3">
                        <button className="btn full-w btn-primary" onClick={(e)=> this.sendForm()}>{ this.state.config && this.state.config.buttonLabelInModal ? this.state.config.buttonLabelInModal : "Submit"}</button>
                    </div>
                </div>
                </>
            :
                <p className="small text-center">{this.state.errorMessage}</p>    
            }

            {this.props.global.isLoading &&
                <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                    </div>
                </div>
            }

            <Helmet>
                {this.state.configCustomCss && <link rel="stylesheet" href={this.state.configCustomCss}/>}
                {this.state.customCssStyle &&  <style type='text/css'>{this.state.customCssStyle}</style>}
            </Helmet>

            </div>
           
           
        )
    }
}

export { PaymentMethod };