import React from 'react';
import { inject, observer } from 'mobx-react';
import {IMaskInput} from 'react-imask';
import {ErrorMessage} from './ErrorMessage';

@inject('vTerminal', 'global')
@observer
class PaymentMethodCCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ccType: 'unknown'
        };
        this.goToReview = this.goToReview.bind(this);
        this.handleTextChangeCC = this.handleTextChangeCC.bind(this);
        this.getRowComponent = this.getRowComponent.bind(this);
        this.resizeContainer = this.resizeContainer.bind(this);
        this.getCCMaskValidator = this.getCCMaskValidator.bind(this);

        this.cardNumber = React.createRef();
        this.cardExpirationDate = React.createRef();
        this.cardCvv = React.createRef();
        this.cardZipcode = React.createRef();
    }

    goToReview(event) {
        event.preventDefault();
        this.props.history.push('/review-confirm');
    }

    handleTextChangeCC(field, value, blur) {
        this.props.vTerminal.handleTextChangeACH(field, value);
        let ccType = this.props.global.creditCardType(this.props.vTerminal.paymentPage.paymentMethods.cardNumber);
        let globalConfig = this.props.config;
        this.setState({ 'ccType': ccType });

        let validators = this.props.global.validators;
        let ccTypeAux = ccType;
        if(ccType === "american-express"){
            ccTypeAux  =  "amex";
        }
        if(ccType === "diners-club"){
            ccTypeAux  =  "diners";
        }

        if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardHolderName && globalConfig.card.inputs.cardHolderName.hidden !== true){
            if (field === "cardHolderName") {
                if(blur === true || !validators.isMaxLength(250, value)){
                    if(!validators.isMaxLength(250, value)){
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardHolderName',null);
                    }
                    else{
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',true);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardHolderName',"Your cardholder name is invalid");
                    }
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardHolderName',null);
                }
               
            }
        }
       

        if (field === "cardNumber") {
            if(!validators.isEmpty(value) && blur === true){
                if(ccTypeAux !== "unknown" && !this.props.vTerminal.getCCTypeValidationsErrorCases() && !validators.stringValidator('card', value)){
                    
                    if(globalConfig?.card[ccTypeAux] === false){
                        this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',`${this.props.global.getCardFullName(ccTypeAux)} not accepted`);
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',true);
                    }else{
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',null);
                    }
                   
                }
                else{
                    if(ccTypeAux === "unknown"){
                        this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',"Your card number is invalid");
                    }else{
                        this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',"Your card number is incomplete");
                    }
                    
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',true);
                }
                
            }else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',null);
            }
            
        }

        if (field === "cardExpirationDate") {

            if(!validators.isEmpty(value) && (blur === true || !validators.stringValidator('exp', value))){
                if(value !== '' && !validators.stringValidator('exp', value)){
                    let expDateYear = value.substr(-2);
                    let expDateMonth = value.substr(0,2);
                    let currentYear =new Date().getFullYear().toString().substr(-2);
                    let currentMonth =new Date().getMonth().toString();

                    if((parseInt(expDateMonth) < 13) && (parseInt(expDateMonth) > 0) && (parseInt(expDateYear) > parseInt(currentYear) || ((parseInt(expDateYear) === parseInt(currentYear)) && (parseInt(expDateMonth) >= parseInt(currentMonth)+1)))){
                        //this.cardCvv.current.element.focus();
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',null);
                    }
                    else{
                        if(parseInt(expDateMonth) > 12){
                            this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is invalid");
                        }else{
                            this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is in the past");
                        }
                        
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                    }
                }
                else{
                    this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is incomplete");
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                } 
            }else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',null);
            }
        }

        if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardCvv && globalConfig.card.inputs.cardCvv.hidden !== true){
            if (field === "cardCvv") {
                if(!validators.isEmpty(value) && (blur === true || (ccType === "american-express" ? !validators.stringValidator('cvvamex', value) : !validators.stringValidator('cvv', value)))){
                    if(value !== '' && (ccType === "american-express" ? !validators.stringValidator('cvvamex', value) : !validators.stringValidator('cvv', value))){
                        //this.cardZipcode.current.element.focus();
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardCvv',null);
                    }else{
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',true);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardCvv',"Your card's security code is incomplete");
                    }
                }else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardCvv',null);
                }
            }
        }

        if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.hidden !== true){
            
            let zipCodeValidator = !validators.stringValidator('zipcode', value);
            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country && Array.isArray(globalConfig.card.inputs.cardZipcode.country)){
                zipCodeValidator = this.props.global.checkZipFormat(globalConfig.card.inputs.cardZipcode.country, value)
            }

            if (field === "cardZipcode") {
                if(!validators.isEmpty(value) && (blur === true || zipCodeValidator)){
                    if(value !== '' && zipCodeValidator){
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',null);
                    }else{
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',true);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',"Your card's zip code is incomplete or incorrect");
                    }
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',null);
                }
                
            }
        }

        /*if (field === "cardCvv" && value === '') {
            this.cardExpirationDate.current.element.focus()
        }
        
        if (field === "cardExpirationDate" && value === '') {
            this.cardNumber.current.element.focus()
        }

        if (field === "cardZipcode" && value === '') {
            this.cardCvv.current.element.focus()
        }*/
       
        if(globalConfig && globalConfig.randomId){
            if (!this.props.vTerminal.validateFieldsByMethod('card', globalConfig)) {
                window.parent.postMessage({
                    event: "callback-payabli-function-ready" + globalConfig.randomId,
                    data: ['card',true]
                }, '*');
            }
            else {
                window.parent.postMessage({
                    event: "callback-payabli-function-ready" + globalConfig.randomId,
                    data: ['card', false]
                }, '*');
            }
        }
        
    }

    resizeContainer(){
        let config = this.props.config;
        window.parent.postMessage({
            event: "callback-payabli-function-resize"+config.randomId,
            data: document.body.scrollHeight
        }, '*');
    }

    async validateField(e, removeBlankSpace){
        if(e){
            let value = removeBlankSpace ? e.target.value?.replace(/ /g, '') : e.target.value;
            await this.handleTextChangeCC(e.target.name,value, true);
        }
        this.resizeContainer();
    }

    getCCMaskValidator(){
        let ccType = this.state.ccType;
        switch(ccType){
            case "american-express":
                return this.props.global.maskValidator('creditCardAmex');
            case "diners-club":
                 return (
                    this.props.vTerminal.paymentPage.paymentMethods.cardNumber && 
                    this.props.vTerminal.paymentPage.paymentMethods.cardNumber.length === 14
                    ) ? this.props.global.maskValidator('creditCardDiners14') 
                    : 
                    this.props.global.maskValidator('creditCardDiners16');
            case "jcb":
                return this.props.global.maskValidator('creditCard');
            default:
               return this.props.global.maskValidator('creditCard');
        }
    }

    getRowComponent(x) {
        let r = this.props.vTerminal.cardRows;
        let rd = this.props.vTerminal.cardInputs;
        let globalConfig = this.props.config;
        var f = r[x];
        var rows = [];
        if (f.length > 0) {
            for (var i = 0; i < f.length; i++) {
               
                if (f[i] === 'cardHolderName') {
                    if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardHolderName && globalConfig.card.inputs.cardHolderName.hidden !== true){
                        rows.push(
                            <div id="cardHolderNameContainer" className={"fullw-440 col-" + rd.cardHolderName.size + " mb-3"} key={i}>
                                {this.props.showPopoverError === true &&
                                <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardHolderName}/>
                                }
                                <div className={rd.cardHolderName.floating ? "form-floating" : ""}>
                                    {rd.cardHolderName.floating === false && 
                                        <label htmlFor="cardHolderName">{rd.cardHolderName.label}</label>
                                    }
                                    <IMaskInput
                                        mask={this.props.global.maskValidator('alphanumericspaces')}
                                        name="cardHolderName"
                                        value={this.props.vTerminal.paymentPage.paymentMethods.cardHolderName ? this.props.vTerminal.paymentPage.paymentMethods.cardHolderName : ''}
                                        unmask={true}
                                        onAccept={
                                            (value, mask) => this.handleTextChangeCC('cardHolderName', value)
                                        }
                                        onBlur={
                                            (e) => this.validateField(e, false)
                                        }
                                        placeholder={rd.cardHolderName.placeholder}
                                        className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsCardHolderNameError ? "form-control input-error component-input-error" : "form-control"}
                                        id="cardHolderName"
                                    />
                                    {rd.cardHolderName.floating === true &&
                                        <label htmlFor="cardHolderName">{rd.cardHolderName.label}</label>
                                    }
                                </div>
                                {this.props.showPopoverError === false &&
                                <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardHolderName}/>
                                }
                            </div>
                        );
                    }
                }
                else if (f[i] === 'cardNumber') {
                    rows.push(
                        <div id="cardNumberContainer" className={"fullw-440 col-" + rd.cardNumber.size + " mb-3"} key={i}>
                            {this.props.showPopoverError === true &&
                            <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardNumber}/>
                            }
                            <div className={this.state.ccType}>
                            <div className={rd.cardNumber.floating ? "form-floating" : ""}>
                                {rd.cardNumber.floating === false &&
                                    <label htmlFor="cardNumber">{rd.cardNumber.label}</label>
                                }
                                <IMaskInput
                                    mask={this.getCCMaskValidator()}
                                    name="cardNumber"
                                    value={this.props.vTerminal.paymentPage.paymentMethods.cardNumber ? this.props.vTerminal.paymentPage.paymentMethods.cardNumber : ''}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeCC('cardNumber', value)
                                    }
                                    onBlur={
                                        (e) => this.validateField(e, true)
                                    }
                                    placeholder={rd.cardNumber.placeholder}
                                    className={"form-control input-cc " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardNumberError ? "component-input-error" : "")}
                                    id="cardNumber"
                                    ref={this.cardNumber}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                />
                                {rd.cardNumber.floating === true &&
                                    <label htmlFor="cardNumber">{rd.cardNumber.label}</label>
                                }
                            </div>
                            </div>
                            {this.props.showPopoverError === false &&
                            <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardNumber}/>
                            }
                        </div>
                    );

                }
                else if (f[i] === 'cardExpirationDate') {
                    rows.push(
                        <div id="cardExpirationDateContainer" className={"fullw-440 col-" + rd.cardExpirationDate.size + " mb-3"} key={i}>
                            {this.props.showPopoverError === true &&
                            <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardExpirationDate}/>
                            }
                            <div className={rd.cardExpirationDate.floating ? "form-floating" : ""}>
                                {rd.cardExpirationDate.floating === false &&
                                    <label htmlFor="cardExpirationDate">{rd.cardExpirationDate.label}</label>
                                }
                                <IMaskInput
                                    mask={this.props.global.maskValidator('cardDate')}
                                    name="cardExpirationDate"
                                    value={this.props.vTerminal.paymentPage.paymentMethods.cardExpirationDate ? this.props.vTerminal.paymentPage.paymentMethods.cardExpirationDate : ''}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeCC('cardExpirationDate', value)
                                    }
                                    onBlur={
                                        (e) => this.validateField(e, true)
                                    }
                                    className={"form-control " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardExpirationDateError ? "component-input-error" : "")}
                                    placeholder={rd.cardExpirationDate.placeholder}
                                    id="cardExpirationDate"
                                    ref={this.cardExpirationDate}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                />
                                {rd.cardExpirationDate.floating === true &&
                                    <label htmlFor="cardExpirationDate">{rd.cardExpirationDate.label}</label>
                                }
                            </div>
                            {this.props.showPopoverError === false &&
                            <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardExpirationDate}/>
                            }
                        </div>
                    );
                }
                else if (f[i] === 'cardCvv') {
                    if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardCvv && globalConfig.card.inputs.cardCvv.hidden !== true){
                        rows.push(
                            <div id="cardCvvContainer" className={"fullw-440 col-" + rd.cardCvv.size + " mb-3"} key={i}>
                                {this.props.showPopoverError === true &&
                                <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardCvv}/>
                                }
                                <div className={rd.cardCvv.floating ? "form-floating" : ""}>
                                    {rd.cardCvv.floating === false &&
                                        <label htmlFor="cardCvv">{rd.cardCvv.label}</label>
                                    }
                                    <IMaskInput
                                        mask={this.state.ccType === "american-express" ? this.props.global.maskValidator('cvvamex') : this.props.global.maskValidator('cvv')}
                                        name="cardCvv"
                                        id="cardCvv"
                                        value={this.props.vTerminal.paymentPage.paymentMethods.cardCvv ? this.props.vTerminal.paymentPage.paymentMethods.cardCvv : ''}
                                        unmask={true}
                                        onAccept={
                                            (value, mask) => this.handleTextChangeCC('cardCvv', value)
                                        }
                                        onBlur={
                                            (e) => this.validateField(e, true)
                                        }
                                        className={"form-control " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardCvvError ? "component-input-error" : "")}
                                        placeholder={rd.cardCvv.placeholder}
                                        ref={this.cardCvv}
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                    />
                                    {rd.cardCvv.floating === true &&
                                        <label htmlFor="cardCvv">{rd.cardCvv.label}</label>
                                    }
                                </div>
                                {this.props.showPopoverError === false &&
                                <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardCvv}/>
                                }
                            </div>
                        );
                    }
                }
                else if (f[i] === 'cardZipcode') {
                    if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.hidden !== true){
                        rows.push(
                            <div id="cardZipcodeContainer" className={"fullw-440 col-" + rd.cardZipcode.size + " mb-3"} key={i}>
                                {this.props.showPopoverError === true &&
                                <ErrorMessage showPopoverError={true} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardZipcode}/>
                                }
                                <div className={rd.cardZipcode.floating ? "form-floating" : ""}>
                                    {rd.cardZipcode.floating === false &&
                                        <label htmlFor="cardZipcode">{rd.cardZipcode.label}</label>
                                    }
                                    <IMaskInput
                                        mask={ globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country ? this.props.global.maskValidator('alphanumeric') : this.props.global.maskValidator('zipcode')}
                                        name="cardZipcode"
                                        id="cardZipcode"
                                        value={this.props.vTerminal.paymentPage.paymentMethods.cardZipcode ? this.props.vTerminal.paymentPage.paymentMethods.cardZipcode : ''}
                                        unmask={true}
                                        onAccept={
                                            (value, mask) => this.handleTextChangeCC('cardZipcode', value)
                                        }
                                        onBlur={
                                            (e) => this.validateField(e, true)
                                        }
                                        className={"form-control " + (this.props.vTerminal.paymentPageErrors.paymentMethodsCardZipcodeError ? "component-input-error" : "")}
                                        placeholder={rd.cardZipcode.placeholder}
                                        ref={this.cardZipcode}
                                        maxLength={globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country ? this.props.global.getZipcodeMaxLength(globalConfig.card.inputs.cardZipcode.country) : 5}
                                    />
                                    {rd.cardZipcode.floating === true &&
                                        <label htmlFor="cardZipcode">{rd.cardZipcode.label}</label>
                                    }
                                </div>
                                {this.props.showPopoverError === false &&
                                <ErrorMessage showPopoverError={false} value={this.props.vTerminal.getpaymentPageErrorsMessages.cardZipcode}/>
                                }
                            </div>
                        );
                    }
                }
            }
        }
        return (<>
            {rows}
            </>
        );
    }

    render() {
        return (
            <div>
                <div className="row">
                    {this.getRowComponent(0)}
                </div>
                <div className="row">
                    {this.getRowComponent(1)}
                </div>
                <div className="row">
                    {this.getRowComponent(2)}
                </div>
            </div>
        );
    }
}

export { PaymentMethodCCard };